<template>
  <Dropdown
    :options="options"
    optionLabel="name"
    optionValue="id"
    :placeholder="label"
    class="select"
  />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Select",
    },
    options: {
      type: Array,
      default: [],
    },
  },
};
</script>

<style scoped>
.select {
  height: 50px;
  width: 250px;
  border-radius: .5rem !important;
  border-color: var(--primary) !important;
  box-shadow: none !important;
  font-family: "klavika" !important;
  font-size: 1.2rem !important;
}
</style>
