<template>
  <div class="menu-admin">
    <div class="menu-left">
      <BasicLogo width="200" :src="companyLogo" alt="Logo Administradora" />
      <div class="menu-building">
        <HorizontalLabel label="Edificio" />
        <BorderTitleSelect
          v-model="store.state.general.selectedBuilding"
          label="Seleccione un Complejo"
          :options="auth.user?.buildings || []"
        />
      </div>
    </div>
    <Menu :items="store.state.auth.menu" />
  </div>
</template>

<script>
import BasicLogo from "../../widgets/logo/BasicLogo.vue";
import Menu from "./Menu";
import BorderTitleSelect from "../../widgets/select/BorderTitleSelect";
import HorizontalLabel from "../../widgets/label/HorizontalLabel.vue";
import store from "../../store";
import { onBeforeMount, ref, watchEffect, computed } from "vue";
import { aws } from "../../constants";

export default {
  components: { BasicLogo, Menu, BorderTitleSelect, HorizontalLabel },
  setup() {
    const companyLogo = ref(null);
    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setBreadcrumbs", []);

      watchEffect(() => {
        if (store?.state?.auth?.user?.logo) {
          const callback = (str) => {
            companyLogo.value = `data:image/png;base64,${str}`;
            store.commit("setLoading", false);
          };
          aws.getFile(store.state.auth.user.logo, callback);
        } 
      });
      
      store.commit("setLoading", false);
    });
    return { store, companyLogo, auth: computed(() => store.state.auth) };
  },
};
</script>

<style scoped>
.menu-admin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.menu-left {
  margin-right: 4rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.menu-building {
  display: flex;
  align-items: center;
  margin: 2rem 0;
}
@media screen and (max-width: 1199px) {
  .menu-admin {
    flex-direction: column;
    align-items: center;
  }
  .menu-left {
    margin-right: 0;
    padding-top: 0;
  }
}
</style>
