<template>
  <div class="menu-master">
    <BasicLogo width="300" />
    <Menu :items="store.state.auth.menu" />
  </div>
</template>

<script>
import BasicLogo from '../../widgets/logo/BasicLogo.vue'
import Menu from './Menu'
import store from '../../store'
import { onBeforeMount } from 'vue'

export default {
  components: { BasicLogo, Menu },
  setup() {
    onBeforeMount(() => store.commit('setBreadcrumbs', []))
    return {store}
  }
}
</script>

<style scoped>
.menu-master {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
</style>