<template>
  <div class="home">
    <MenuMaster v-if="store.state.auth.role.name === roles.master" />
    <MenuAdmin v-else />
  </div>
</template>

<script>
import MenuMaster from "../components/menu/MenuMaster";
import MenuAdmin from "../components/menu/MenuAdmin";
import store from "../store";
import { roles } from "../constants";

export default {
  components: {
    MenuMaster,
    MenuAdmin,
  },
  setup() {
    return { store, roles };
  },
};
</script>

<style scoped></style>
