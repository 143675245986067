<template>
  <p class="label">{{ label }}</p>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Mensaje",
    },
  },
};
</script>

<style scoped>
.label {
  color: var(--primary-dark);
  margin-right: 1rem;
}
</style>
