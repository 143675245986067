<template>
  <div class="menu">
    <div class="menu-list">
      <MenuItemCard
        v-for="item in items"
        :key="item.label"
        :name="item.label"
        @click="onClick(item)"
        :badge="loadBadge(item)"
      />
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import MenuItemCard from "../../widgets/menu/MenuItemCard.vue";
import store from "../../store";
import { roles } from "../../constants";

export default {
  components: { MenuItemCard },
  props: {
    items: {
      type: Array,
      default: [],
    },
  },
  setup() {
    const router = useRouter();

    const onClick = (item) => {
      const role = store.state.auth.role.name;
      if (role !== roles.master) {
        if (store.state.general.selectedBuilding) {
          router.push(item.to);
        } else {
          store.state.toast.add({
            severity: "info",
            summary: "",
            detail: "Debe seleccionar un complejo!",
            life: 5000,
          });
        }
      } else {
        router.push(item.to);
      }
    };

    const loadBadge = (data) => {
      switch (data.key) {
        case "building":
          return store.state.auth.buildingBadge;
        case "commonExpenses":
          return store.state.auth.paymentBadge;
        case "assembly":
          return store.state.auth.assemblyBadge;
        case "message":
          return store.state.auth.messageBadge;
        case "personnel":
          return store.state.auth.licenseBadge;
        default:
          break;
      }
    };

    return { onClick, loadBadge };
  },
};
</script>

<style scoped>
.menu {
  display: flex;
  justify-content: center;
}
.menu-list {
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;
}
@media screen and (min-width: 992px) {
  .menu-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1440px) {
  .menu-list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
</style>
