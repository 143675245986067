<template>
  <div class="container">
    <img :src="require(`../../assets/icons/${name}.svg`)" :alt="name" />
    <p class="text">{{ name }}</p>
    <p v-if="badge" class="badge">{{ badge }}</p>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "Menu Item",
    },
    badge: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.container {
  width: 172px;
  height: 194px;
  border-radius: 0.5rem;
  background-color: var(--primary-light);
  box-shadow: 0 0 20px rgba(35, 141, 130, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.container > img {
  margin-top: 1.5rem;
}
.text {
  margin-bottom: 1rem;
  color: var(--primary-dark);
}
.badge {
  position: absolute;
  right: -.75rem;
  top: -.75rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: .75rem;
  font-size: .6rem;
  font-family: 'klavika';
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--danger);
  color: #fff;
}
</style>
